import React, { useState, useEffect } from 'react';
import { useBasket, useTranslations } from '~/services';
import { LoadingSpinner } from '../LoadingSpinner';
import { Text } from '../Text';
import { BasketItem } from './components/BasketItem';
import { FreightContextHeader } from './components/FreightContextHeader';
import { BasketWrapper, Loading } from './styled';

export const Basket = () => {
    const { translate } = useTranslations();
    const { data: basket, isLoading } = useBasket();

    const basketHasMultipleContexts = (basket?.contexts?.length || 0) > 1;
    const freightWithContextList = basket?.contexts?.flatMap((context) =>
        context?.freights?.map((freight) => ({ ...freight, context }))
    );

    const [imageContextName, setImageContextName] = useState<string | undefined | null>(undefined);

    // Set which context initially gets to display images on first data load
    useEffect(() => {
        if (imageContextName !== undefined || !basketHasMultipleContexts) {
            return;
        }

        setImageContextName(freightWithContextList?.[0]?.context.name);
    }, [basketHasMultipleContexts, freightWithContextList, imageContextName]);

    if (isLoading) {
        return (
            <Loading>
                <LoadingSpinner size={40} />
            </Loading>
        );
    }

    if (!basket?.quantity) {
        return <Text>{translate('basket.empty')}</Text>;
    }

    return (
        <BasketWrapper>
            {freightWithContextList?.map((freight, index) =>
                !freight ? null : (
                    <li
                        key={`${freight.deliveryId}${index}`}
                        onMouseEnter={() => {
                            if (
                                basketHasMultipleContexts &&
                                imageContextName !== freight.context.name
                            ) {
                                setImageContextName(freight.context.name);
                            }
                        }}
                    >
                        <FreightContextHeader
                            contextName={freight.context.name || ''}
                            freight={freight}
                        />

                        <ul>
                            {freight.brands?.flatMap((brand) =>
                                brand.items?.map((item, index) => (
                                    <BasketItem
                                        key={`${freight.deliveryId}${item.itemNumber}${index}`}
                                        brandName={brand.name || ''}
                                        item={item}
                                        shouldDisplayImage={
                                            imageContextName === freight.context.name ||
                                            !basketHasMultipleContexts
                                        }
                                    />
                                ))
                            )}
                        </ul>
                    </li>
                )
            )}
        </BasketWrapper>
    );
};
