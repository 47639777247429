import NextLink from 'next/link';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Trash from '~/icons/iconography/trash.svg';
import { ShopTypes } from '~/lib/data-contract';
import { useResponseHandler, useSetBasketItem, useTranslations } from '~/services';
import { ErrorMessage, Form } from '~/shared/components/Form';
import { ImageOverlay, ProductImage } from '~/shared/components/Image';
import {
    SelectAmount,
    SelectAmountForm,
    SelectAmountTypeFormFields,
} from '~/shared/components/SelectAmount';
import { useCurrency } from '~/shared/hooks';
import gtm, { getGABasketItem } from '~/shared/tracking/gtm';
import PlaceholderImage from '../../NewComponents/PlaceholderImage';
import {
    ItemAmountSelector,
    ItemImageContainer,
    ItemImageWrapper,
    ItemName,
    ItemNumber,
    ItemPrice,
    ItemPricePerItem,
    ItemQuantityContainer,
    ItemType,
    ItemTypeVariant,
    ItemVariant,
    ItemWrapper,
    LineItem,
    PriceWrapper,
    Remove,
} from './styled';

type BasketItemProps = {
    brandName: string;
    item: ShopTypes.BasketItemResponse;
    shouldDisplayImage: boolean;
};

export const BasketItem = ({ brandName, item, shouldDisplayImage }: BasketItemProps) => {
    const formatCurrency = useCurrency();
    const { translate } = useTranslations();
    const { mutate: setBasketItem, isLoading } = useSetBasketItem();
    const name = `${item?.name ? `${item?.name} ` : ''}${item?.name2 ?? ''}`;

    const methods = useForm<SelectAmountForm>({
        defaultValues: { [SelectAmountTypeFormFields.Quantity]: item.quantity },
    });

    const [basketValidationError, clearBasketValidationError, basketValidationHandler] =
        useResponseHandler<ShopTypes.BasketValidationError>({
            statusCodes: [400],
            onError: () => {
                methods?.setValue(SelectAmountTypeFormFields.Quantity, item.quantity);
            },
        });

    const salesUnit = translate('basket.itemSalesUnit').replace(
        '[SALES_UNIT]',
        item.salesUnit || '',
    );

    const handleGA = useCallback(
        (quantity: number) => {
            if (quantity > -1 && quantity < item.quantity) {
                const amount = item.quantity - quantity;
                item.quantity = amount;

                gtm.ecommerce.removeFromCart(
                    [getGABasketItem(item, brandName, 0)],
                    (item.price?.priceInclVat ?? 0) * amount,
                );
            } else if (quantity > -1 && quantity > item.quantity) {
                const amount = quantity - item.quantity;
                const value = (item?.price?.priceInclVat ?? 0) * amount;
                const gaItem = getGABasketItem(item, brandName, 0);
                gaItem.quantity = amount;
                const items = [gaItem];

                gtm.ecommerce.addToCart(value, items);
            }
        },
        [brandName, item],
    );

    // Update form value on item change
    useEffect(() => {
        methods?.setValue(SelectAmountTypeFormFields.Quantity, item.quantity);
    }, [item.quantity, methods]);

    //  Call BE on form value update
    useEffect(() => {
        const subscription = methods?.watch((values) => {
            const { quantity } = values;
            const shouldUpdate = quantity && quantity !== item.quantity;

            if (shouldUpdate) {
                clearBasketValidationError();
                setBasketItem(
                    {
                        itemNo: item.itemNumber,
                        quantity,
                        warehouseId: item.wareHouseId,
                    },
                    basketValidationHandler,
                );

                handleGA(quantity);
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [
        clearBasketValidationError,
        item.itemNumber,
        item.quantity,
        item.wareHouseId,
        methods,
        setBasketItem,
        basketValidationHandler,
        handleGA,
    ]);

    const deleteBasketItem = () => {
        clearBasketValidationError();
        setBasketItem({
            itemNo: item.itemNumber,
            quantity: 0,
            warehouseId: item.wareHouseId,
        });
        handleGA(0);
    };

    return (
        <ItemWrapper>
            <LineItem>
                <ItemImageContainer shouldDisplayImage={shouldDisplayImage}>
                    <ItemImageWrapper>
                        {item.image?.link ? (
                            <ProductImage
                                src={item.image.link}
                                alt={name}
                                title={name}
                                style={{ objectFit: 'contain' }}
                                width={80}
                                height={80}
                            />
                        ) : (
                            <PlaceholderImage />
                        )}
                        <ImageOverlay />
                    </ItemImageWrapper>
                </ItemImageContainer>

                <ItemType>{brandName}</ItemType>

                <Remove type="button" onClick={deleteBasketItem}>
                    <Trash alt={translate('generic.remove')} />
                </Remove>

                <NextLink href={item.itemUrl || ''} passHref legacyBehavior>
                    <ItemName animation="reverse">{name}</ItemName>
                </NextLink>

                <ItemVariant>
                    {item.variantType && item.variantName ? (
                        <>
                            <ItemTypeVariant>{item.variantType}: </ItemTypeVariant>
                            {item.variantName}
                        </>
                    ) : null}
                </ItemVariant>

                <ItemNumber>
                    {translate('generic.itemNumber')} {item.itemNumber}
                </ItemNumber>

                <ItemQuantityContainer>
                    <ItemAmountSelector>
                        <Form methods={methods} onSubmit={() => undefined}>
                            <SelectAmount
                                debounce
                                disabled={isLoading}
                                name={SelectAmountTypeFormFields.Quantity}
                                smallSize
                            />
                        </Form>
                    </ItemAmountSelector>

                    <ItemPricePerItem>
                        {item.price ? formatCurrency(item.price.priceInclVat) : formatCurrency(0)}{' '}
                        {salesUnit}
                    </ItemPricePerItem>

                    <PriceWrapper>
                        <ItemPrice>
                            {item.subTotal
                                ? formatCurrency(item.subTotal.priceInclVat)
                                : formatCurrency(0)}
                        </ItemPrice>
                    </PriceWrapper>
                </ItemQuantityContainer>

                <ErrorMessage>
                    {basketValidationError || item.validationError
                        ? translate(
                              (basketValidationError || item.validationError)?.validationText?.name,
                              (basketValidationError || item.validationError)?.validationText
                                  ?.values,
                          )
                        : null}
                </ErrorMessage>
            </LineItem>
        </ItemWrapper>
    );
};
